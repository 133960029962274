<template>
	<div class="grid layout-main">
		<Toast />
		<ConfirmDialog />
		<Dialog header="Simulation conditions" :modal="true" v-model:visible="showImage" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw', height: '70%'}">
			<div>
				<img :src="baseUrl + '/simulation/image?use_case=' + useCase.value + '&var_name=' + varNames[selectedVar]" style="max-width: 100%;" />
			</div>
			<template #footer>
				<Button class="mt-4" label="Close" icon="pi pi-check" autofocus @click="showImage=false" />
			</template>
		</Dialog>

		<div class="col-3 flex flex-column">
			<div class="card flex-grow-1 mb-0">
				<FileUpload mode="basic" name="dataFile" :url="baseUrl + '/simulation/upload'" @error="onError" @before-upload="onUploadStart" @progress="onProgress" @upload="onBasicUpload" :auto="true" class="p-button-warning" style="width:100%" />
				<ProgressBar :value="uploadProgress" class="mt-3">
					{{uploadProgress}}%
				</ProgressBar>
				<div class="mt-4"><h3>Use case</h3></div>
				<div class="flex ml-4 mt-3">
					<div class="flex-grow-1 mr-3">
						<Dropdown v-model="useCase" :options="useCases" optionLabel="name" @change="onUseCaseChanged" placeholder="Select a use case" style="width:100%" />
					</div>
					<div>
						<Button class="p-button-warning" icon="pi pi-trash" @click="onDeleteUseCase()" />
					</div>
				</div>
				<div class="mt-4"><h3>Variable name</h3></div>
				<div class="flex mb-2" v-for="(value, key) in varNames" :key="key">
					<div class="flex-grow-1 ml-4">{{key}}</div><div><RadioButton @change="onVarChanged" name="checkedVar" :value="key" v-model="selectedVar" :binary="true" /></div>
				</div>
				<div class="mt-2 mb-2"><h3>Type</h3></div>
				<div class="flex mb-2">
					<div class="flex-grow-1 ml-4">
						<Dropdown v-model="selectedType" @change="onTypeChanged" :options="types" optionLabel="name" placeholder="Select a type" style="width:100%" />
					</div>
				</div>
			</div>
		</div>
		<div class="col-3 flex flex-column">
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">File</span>
						<div class="text-900 font-medium text-xl">{{fileName}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-file text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Part identification</span>
						<div class="text-900 font-medium text-xl">{{partName}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-box text-orange-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Material</span>
						<div class="text-900 font-medium text-xl">{{material}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-th-large text-purple-500 text-xl"></i>
					</div>
				</div>
			</div>
			<div class="card flex-grow-1 mb-0">
				<div class="flex justify-content-between">
					<div>
						<span class="block text-500 font-medium mb-3">Nozzle diameter</span>
						<div class="text-900 font-medium text-xl">{{nozzleDiameter}} mm</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-circle text-green-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-6 flex flex-column">
			<div class="card flex-grow-1 mb-0">
				<div style="position: relative; height: 100%;">
					<div style="position: absolute; left: 0; right: 0;">
						<Button label="Simulation conditions" style="z-index: 1" @click="showImage=true"></Button>
					</div>
					<div style="width:100%;height:calc(100% - 2em);" id="plotlyDiv" ref="plotlyDiv">
					</div>
					<div class="flex flex-grow-1 flex-column" style="height: 2em;">
						<div class="flex align-items-center" v-if="!isTemperature">
							<label for="deformationSwitch" class="mr-2">Show deformation</label> <InputSwitch id="deformationSwitch" v-model="showDeformation" @change="onSwitchDeformation" />
						</div>
						<div v-if="isTemperature" class="flex flex-row align-items-center flex-grow-1">
							<div class="flex flex-grow-0 align-items-center justify-content-center mr-4">0</div>
							<Slider class="flex flex-grow-1 align-items-center justify-content-center" @slideend="onThermalTimeSlideEnd" @change="onThermalTimeChange" :max="thermalTimes.length-1" v-model="thermalTimeIndex"></Slider>
							<div class="flex flex-grow-0 align-items-center justify-content-center ml-4">{{formatSeconds(thermalTimeLimit)}}</div>
						</div>
						<div v-if="isTemperature" class="flex flex-column align-items-center">
							<div class="flex align-items-center">{{formatSeconds(thermalTime.name)}}</div>
						</div>
					</div>
					<div v-if="loading" style="background: rgba(255, 255, 255, 0.4); position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
						<div class="flex justify-content-center flex-wrap card-container" style="height: 100%">
							<div class="flex align-items-center justify-content-center">
								<ProgressSpinner />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Plotly from 'plotly.js-gl3d-dist';
import axios from 'axios';

export default {
	data(){
		return {
			selectedType: null,
			types: [
				{name: 'Simulated data', code: 'S'}
			],
			varNames: {
				'Displacement X (mm)': 'X',
				'Displacement Y (mm)': 'Y',
				'Displacement Z (mm)': 'Z',
				'Displacement magnitude (mm)': 'Magnitude',
				'Temperature evolution (ºC)': 'Temperature'
			},
			thermalTimes: [
			],
			thermalTime: null,
			thermalTimeLimit: 0,
			thermalTimeIndex: null,
			fileName: '',
			partName: '',
			date: '',
			material: '',
			nozzleDiameter: 0,
			uploadProgress: 0,
			selectedVar: null,
			loading: false,
			showDeformation: false,
			showImage: false,
			updateTimer: null,
			useCases: [],
			useCase: null,
			baseUrl: process.env.VUE_APP_REST_BASE_URL
		}
	},
	computed: {
		isTemperature() {
			return this.varNames[this.selectedVar] == 'Temperature';
		}
	},
	mounted: function(){
		this.selectedVar = Object.keys(this.varNames)[0];
		this.selectedType = this.types[0];
		this.updateUseCases();
		//this.updateChart();
		//this.updateThermalTimes();
	},
	unmounted: function(){
		if (this.updateTimer != null){
			clearTimeout(this.updateTimer);
			this.updateTimer = null;
		}
	},
	methods: {
		formatSeconds(secs){
			var min = Math.floor(secs / 60);
			secs = (secs % 60).toString();
			var h = Math.floor(min / 60).toString();
			min = (min % 60).toString();
			if (min.length < 2) min = "0" + min;
			if (secs.length < 2) secs = "0" + secs;
			if (h > 0)
				return h + ":" + min + ":" + secs;
			else
				return min + ":" + secs;
		},
		parseResponse(parsedResponse){
			this.fileName = parsedResponse.file_name;
			this.nozzleDiameter = parsedResponse.nozzle_diameter;
			this.partName = parsedResponse.part_name;
			this.date = parsedResponse.date_time;
			this.material = parsedResponse.material;
			var parsedFigure = JSON.parse(parsedResponse.figure);
			Plotly.newPlot(
				this.$refs["plotlyDiv"],
				parsedFigure.data,
				parsedFigure.layout,
				{ responsive: true }
			);
		},
		onUploadStart(){
			this.loading = true;
			this.uploadProgress = 0;
			this.selectedVar = 'Displacement X';
			this.showDeformation = false;
		},
		onBasicUpload(event){
			var parsedResponse = JSON.parse(event.xhr.response);
			console.log(parsedResponse);
			this.updateUseCases();
		},
		onError(){
			console.log("Something went wrong");
			this.loading = false;
			this.$toast.add({
				severity: "error",
				summary: "Error",
				detail: "Error uploading data to the server",
				life: 3000,
			});
		},
		onProgress(event){
			this.uploadProgress = event.progress;
		},
		onDeleteUseCase(){
			this.$confirm.require({
                message: 'Are you sure you want to delete "' + this.useCase.name + '"?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    axios
						.delete('/simulation/use-cases?use_case=' + this.useCase.value)
						.then(() => {
							this.useCase = null;
							this.updateUseCases();
						});
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
                onHide: () => {
                    //Callback to execute when dialog is hidden
                }
            });
		},
		updateUseCases(){
			axios
				.get('/simulation/use-cases')
				.then(response => {
					this.useCases = [];
					for (let i = 0; i < response.data.use_cases.length; i++)
						this.useCases.push({name: response.data.use_cases[i], value: response.data.use_cases[i]}); 

					if (this.useCase == null && this.useCases.length > 0){
						this.useCase = this.useCases[0];
						this.updateChart();
						this.updateThermalTimes();
					}else
						this.loading = false;
				});
		},
		updateChart(){
			this.loading = true;
			axios
				.get('/simulation/data?use_case=' + this.useCase.value +
						'&var_name=' + this.varNames[this.selectedVar] + 
						'&thermal_time=' + (this.thermalTime != null ? this.thermalTime.code : '') +
						'&show_deformation=' + this.showDeformation)
				.then(response => {
					this.parseResponse(response.data);
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting data from server",
						life: 3000,
					});
				});
		},
		updateThermalTimes(){
			axios
				.get('/simulation/evolution-times?use_case=' + this.useCase.value)
				.then(response => {
					console.log(response.data);
					this.thermalTimes = response.data.times;
					this.thermalTime = this.thermalTimes[0];
					this.thermalTimeLimit = this.thermalTimes[this.thermalTimes.length - 1].name;
				});
		},
		onThermalTimeChange(){
			this.thermalTime = this.thermalTimes[this.thermalTimeIndex];
			if (this.updateTimer != null) clearTimeout(this.updateTimer);
			this.updateTimer = setTimeout(this.updateChart, 500);
		},
		onThermalTimeSlideEnd(){
			this.thermalTime = this.thermalTimes[this.thermalTimeIndex];
			if (this.updateTimer != null){
				clearTimeout(this.updateTimer);
				this.updateTimer = null;
			}
			this.updateChart();
		},
		onUseCaseChanged(){
			this.updateChart();
			this.updateThermalTimes();
		},
		onVarChanged(){
			this.updateChart();
		},
		onTypeChanged(){
			this.updateChart();
		},
		onSwitchDeformation(){
			this.updateChart();
		}
	}
}
</script>